import { useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { Footer } from "./components/Footer";
import { NavBar } from "./components/Nav";
import About from "./pages/About/About";
import Career from "./pages/career/Career";
import { Home } from "./pages/Home";
import Details from "./pages/Portfolio/Details";
import Portfolio from "./pages/Portfolio/Portfolio";
// admin
import Login from "./pages/Admin/Auth/Login";
// import SignUp from "./pages/Admin/Auth/SignUp";
// import Forget from "./pages/Admin/Auth/Forget";
import Dashboard from "./pages/Admin/Dashboard/Dashboard";
import Job from "./pages/Admin/Dashboard/job/index";
import JobCreate from "./pages/Admin/Dashboard/job/Create";
import JobUpdate from "./pages/Admin/Dashboard/job/Update";
import Faq from "./pages/Admin/Dashboard/Faq/index";
import Review from "./pages/Admin/Dashboard/Review/index";
import CreateReview from "./pages/Admin/Dashboard/Review/Create";
import UpdateReview from "./pages/Admin/Dashboard/Review/Update";
import Project from "./pages/Admin/Dashboard/Project/index";
import CreateFAQ from "./pages/Admin/Dashboard/Faq/Create";
import UpdateFAQ from "./pages/Admin/Dashboard/Faq/Update";
import CreateProject from "./pages/Admin/Dashboard/Project/Create";
import UpdateProject from "./pages/Admin/Dashboard/Project/Update";
import DetailProject from "./pages/Admin/Dashboard/Project/Detail/index";
import CreateDetailProject from "./pages/Admin/Dashboard/Project/Detail/Create";
import Protected from "./components/Protected";
import Quote from "./pages/Admin/Dashboard/Quote";
import Subscription from "./pages/Admin/Dashboard/Subscription";
import ChatBot from "./pages/ChatBot";

function App() {
	const location = useLocation();
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const [authUser, setAuthUser] = useState(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		setAuthUser(JSON.parse(localStorage.getItem("userData")));
		if (authUser) {
			setLoading(false);
		}
	}, []);

	return (
		<>
			{location.pathname !== "admin" && location.pathname.split("/")[1] !== "admin" && <NavBar show={show} setShow={setShow} handleClose={handleClose} handleShow={handleShow} />}
			{loading && (
				<Routes>
					<Route path="/" exact element={<Home show={show} setShow={setShow} handleClose={handleClose} handleShow={handleShow} />} />
					<Route path="portfolio" element={<Portfolio />} />
					<Route path="details/:id" element={<Details />} />
					<Route path="about" element={<About show={show} setShow={setShow} handleClose={handleClose} handleShow={handleShow} />} />
					<Route path="career" element={<Career />} />
					{authUser !== null ? (
						<Route path="/admin" exact element={<Dashboard />}>
							<Route path="/admin" exact element={<Project />} />
							<Route path="create" element={<CreateProject />} />
							<Route path="update/:slug" element={<UpdateProject />} />
							<Route path="detail/:slug" element={<DetailProject />} />
							<Route path="detail/create/:slug" element={<CreateDetailProject />} />
							<Route path="quote" element={<Quote />} />
							<Route path="subscription" element={<Subscription />} />
							<Route path="job" element={<Job />} />
							<Route path="job/create" element={<JobCreate />} />
							<Route path="job/update/:slug" element={<JobUpdate />} />
							<Route path="faq" element={<Faq />} />
							<Route path="faq/create" element={<CreateFAQ />} />
							<Route path="faq/update/:slug" element={<UpdateFAQ />} />
							<Route path="review" element={<Review />} />
							<Route path="review/create" element={<CreateReview />} />
							<Route path="review/update/:slug" element={<UpdateReview />} />
							<Route path="*" element={<Protected />} />
						</Route>
					) : (
						<>
							{authUser === null && (
								<>
									<Route path="*" element={<Protected />} />
									<Route path="/admin/login" element={<Login />} />
									{/* <Route path='/admin/register' element={<SignUp />} /> */}
								</>
							)}
						</>
					)}
				</Routes>
			)}
			{location.pathname !== "admin" && location.pathname.split("/")[1] !== "admin" && <Footer />}
			{/* {console.log(location.pathname.split('/'))} */}
			{false && <ChatBot />}
		</>
	);
}

export default App;
